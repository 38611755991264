class CookieNotice {
    constructor($element) {
        this.$element = $element;
        this.hasCookie = localStorage.getItem('accept-cookies');

        if (this.hasCookie === null) {
            this.startObserver();
            this.showNotice();
        } else {
            this.removeNotice();
        }
    }

    startObserver() {
        this.$element.find('button.accept').on('click', () => {
            localStorage.setItem('accept-cookies', 'true');
            this.removeNotice();
        });
    };

    removeNotice() {
        this.$element.remove();
    };

    showNotice() {
        this.$element.removeClass('hide');
    };
}

$(document).ready(function () {
    let $element = $('#cookie-notice');
    if ($element.length) {
        new CookieNotice($element);
    }
});

