// import 'bootstrap/less/bootstrap.less'
import './../less/bootstrap.less'

import 'jquery'
window.$ = global.$ = window.jQuery = global.jQuery = require('jquery');

import 'bootstrap/js/transition'
import 'bootstrap/js/button'
import 'bootstrap/js/carousel'
import 'bootstrap/js/collapse'
import 'bootstrap/js/dropdown'
import 'lazysizes'

import './cookie-notice'
